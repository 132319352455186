import { onNextRepaint } from '~/shared/utils/animation';
import { getCSRFToken } from '~/shared/utils/request';

export const DrbDialog = {
  bindListeners() {
    /*
      Open a remote dialog via the `data-dialog-open-remote` attribute

      ```
        <a href="/remote-url" data-dialog-open-remote="dialog-id">Open Dialog</a>
        or <button data-remote-url="/remote-url" data-dialog-open-remote="dialog-id">Open Dialog</button>
      ```
    */
    document.addEventListener('click', (e) => {
      const remoteDialogTrigger = e.target.closest('[data-dialog-open-remote]');
      if (!remoteDialogTrigger) return;

      e.preventDefault();

      const dialogId = remoteDialogTrigger.getAttribute('data-dialog-open-remote');
      const url = remoteDialogTrigger.getAttribute('href') || remoteDialogTrigger.getAttribute('data-remote-url');
      const routeUrl = remoteDialogTrigger.getAttribute('data-remote-route-url');
      const shouldReload = !remoteDialogTrigger.hasAttribute('data-remote-disable-reload');
      const preventLightDismiss = remoteDialogTrigger.hasAttribute('data-dialog-prevent-light-dismiss');
      if (!url || !dialogId) return;

      if (routeUrl && (e.ctrlKey || e.metaKey)) {
        window.open(routeUrl, '_blank');
        return;
      }

      const options = {
        url, dialogId, shouldReload, preventLightDismiss, routeUrl,
      };

      this.openRemote(options);
    });

    window.addEventListener('popstate', (event) => {
      if (event.state?.action === 'OPEN_DIALOG') {
        // open the remote dialog with the options from the history state
        this.openRemote({
          ...event.state.options,
          // Prevent creating new history entry
          routeUrl: null,
        });
      } else if (event.state?.action === 'CLOSE_DIALOG') {
        // close the dialog with the id from the history state
        const dialog = document.querySelector(`drb-dialog#${event.state.dialogId}`);
        dialog?.close();
      }
    });
  },

  /*
    Open a remote dialog with the given url and dialog id (via helper method).

    ```
    import { DrbDialog } from '~/web-components/drb-dialog/drb-dialog-helpers';
    DrbDialog.openRemote('/remote-url', 'dialog-id')
    ```
  */
  openRemote(options) {
    const {
      url, dialogId, shouldReload = true, preventLightDismiss = false, routeUrl = null,
    } = options;

    let dialog = document.querySelector(`drb-dialog#${dialogId}`);

    // if dialog with matching id doesn't exist, create it
    if (!dialog) {
      dialog = document.createElement('drb-dialog');
      dialog.id = dialogId;
      dialog.innerHTML = `
        <div slot="dialog-outer"></div>
      `;

      document.body.appendChild(dialog);
    }

    // set the remote attributes and open the dialog
    dialog.remoteUrl = url;
    dialog.remoteShouldReload = shouldReload;
    dialog.preventLightDismiss = preventLightDismiss;

    // if `routeUrl` is provided, update the browser history state
    if (routeUrl) {
      window.history.replaceState({
        action: 'CLOSE_DIALOG',
        ignoreLegacyRouterCSRF: getCSRFToken(),
        dialogId,
        title: document.title,
      }, '');

      window.history.pushState({
        action: 'OPEN_DIALOG',
        options,
        ignoreLegacyRouterCSRF: getCSRFToken(),
        title: document.title,
      }, '', routeUrl);
    }

    dialog.addEventListener('drb-dialog-closed', () => {
      if (window.history.state.action === 'OPEN_DIALOG' && window.history.state.options?.dialogId === dialogId) {
        window.history.back();
      }
    }, { once: true });

    // wait for the repaint so the dialog has mounted to the DOM
    onNextRepaint(() => {
      dialog.open();
    });
  },
};
